import { useDispatch } from "react-redux";
import HelperService from "../../../services/helper.service";
import * as ShoppingCartStore from '../../../store/shoppingcart/actions';

const OrderTable = ({products, cart, setActiveCart}) => {

    const dispatch = useDispatch();  

    const removeProduct = (index) => {
        products.splice(index, 1);
        cart['order-product-bundle-groups'] = products;
        setActiveCart(cart);
        dispatch(ShoppingCartStore.setFinalShoppingCart(cart));
        dispatch(ShoppingCartStore.removeFromShoppingCart(index)); 
    }

    let rows = [];
    rows.push(
        <div key="0" className="pricerow labels">
            <div className="settings"></div>
            <div className="label">Artikel</div>
            <div className="count">Anzahl</div>
            <div className="singleprice">Einzelpreis</div>
            <div className="totalprice">Gesamtpreis</div>
        </div>
    )
    let rowcount = 1;
    let productbundles = HelperService.generateOrderTable(products, true)
    if (productbundles){

        productbundles.forEach((product) => {   
            rows.push(           
                <div key={rowcount} className="pricerow">
                    <div className="settings"><button className="delete" title="Artikel entfernen" onClick={() => removeProduct(product.index)}></button></div>
                    <div className="label">
                        {product.image}
                        <span className="productlabel">{product.name}
                            <span className="hidden-xs">{product.description}</span>
                            {product.appointmenttext ? <span className="small">{product.appointmenttext}</span> : ''}
                            {product.subproducttext ? <span className="small">{product.subproducttext}</span> : ''}
                        </span>
                    </div>
                    <div className="count">{product.quantity}</div>
                    <div className="singleprice">{HelperService.formatPrice(product.singleprice)}</div>
                    <div className="totalprice">{HelperService.formatPrice(product.totalprice)}</div>
                </div>            
            );
            rowcount++;
        });
    }
    return (
        <>
        <div className="ordertable">{rows}</div>
        </>
    )
}

export default OrderTable;