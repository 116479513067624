import { useSelector } from 'react-redux';

const ProductDeliveryTime = () => {

    const distribution = useSelector(state => state.productpage.distributionproducts);
    const shippingid = useSelector(state => state.productpage.shipping.id);
    const contingent = useSelector(state => state.productpage.productdata.contingent);

    let deliveryHtml;
    distribution.forEach((packaging) => {
        let shippingitems = packaging['shipping-packaging']['shipping-provider-products'];
        shippingitems.forEach((item) => {
            item['shipping-method-products'].forEach((method) => {
                if (method.id === shippingid && contingent !== 0){   
                    deliveryHtml = <span className="deliverytime">Lieferzeit: <span className="dt">{method['delivery-time']}</span></span>
                }
            });
        });
    });

    return (
        <>
        {deliveryHtml}
        </>
    );

}

export default ProductDeliveryTime;
