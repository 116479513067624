import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ShoppingCartStore from '../store/shoppingcart/actions';

const OrderFailedPage = () => {  

    const dispatch = useDispatch();
    dispatch(ShoppingCartStore.resetShoppingCart());

    const speech = useSelector(state => state.global.speech);
    const error = useSelector(state => state.global.apierror);

    return (        
        <div className="container nopadding overview">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>Bestellung fehlgeschlagen</h1>                    
                    {error 
                    ? 
                    <p><strong>Wir haben folgende Ursache festgestellt:</strong><br />{error}</p> 
                    :   
                    <>
                    <p><strong>Mögliche Ursachen:</strong><br />Der Artikel ist bereits ausverkauft<br />Es kam zu einem Systemfehler</p> 
                    <p><strong>Ups da ist wohl was schiefgelaufen.</strong><br />Glücklicherweise passiert es nicht häufig, aber selbstverständlich kann auch während des Bestellprozesses etwas schiefgehen.</p>
                    <p><br />Bitte versuche {speech !== 'informal' ? 'n Sie ' : ''} es noch einmal.</p>
                    </>
                    }
                    <p><br /><Link className='button' to="?path=start">Zurück zur Startseite</Link></p>
                </div>
            </div>
        </div>
    );
}

export default OrderFailedPage;
