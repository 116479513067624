import { useForm } from 'react-hook-form';

const ChangeShippingForm = ({data, setData, setShowShippingForm}) => {

    const { register, handleSubmit, formState: {errors} } = useForm();

    const emptyErrorMessage = 'Bitte fülle dieses Feld aus';
    const postalErrorMessage = 'Fehlerhafte Postleitzahl';

    const onSubmit = (values) => {

        setData({
            'first-name': values.firstname,
            'last-name': values.lastname,
            'street': values.street,
            'country-code': 'DE',
            'postal-code': values.postal,
            'city': values.city,
            'email': data.email
        });

        setShowShippingForm(false);
    }
    
    return (
        <>
        <div className="edit-adressdata">
            <h2>Lieferadresse:</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="form-add">                
                <div className="third-left standard mandatory">
                    <label>Vorname<span className="mandatory">*</span></label>
                    <div className="formfieldholder">
                        <input 
                            type="text" 
                            defaultValue={data['first-name']} 
                            {...register('firstname', {
                                required: emptyErrorMessage
                            })}
                        />
                        {errors.firstname && <div className="error">{errors.firstname.message}</div>}
                    </div>
                </div>                 
                <div className="third-middle standard mandatory">
                    <label>Nachname<span className="mandatory">*</span></label>
                    <div className="formfieldholder">
                        <input 
                            type="text" 
                            defaultValue={data['last-name']} 
                            {...register('lastname', {
                                required: emptyErrorMessage
                            })}
                        />
                        {errors.lastname && <div className="error">{errors.lastname.message}</div>}                                            
                    </div>
                </div>
                <div className="third-left standard mandatory">
                    <label>Straße / Hausnummer<span className="mandatory">*</span></label>
                    <div className="formfieldholder">
                        <input 
                            type="text" 
                            defaultValue={data['street']} 
                            {...register('street', {
                                required: emptyErrorMessage      
                            })}
                        />
                       {errors.street && <div className="error">{errors.street.message}</div>}                      
                    </div>
                </div>
                <div className="third-middle plz mandatory">
                    <label>PLZ<span className="mandatory">*</span></label>
                    <div className="formfieldholder">
                        <input 
                            type="text" 
                            defaultValue={data['postal-code']} 
                            maxLength={5}
                            {...register('postal', {
                                required: emptyErrorMessage,
                                pattern: {
                                  value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                                  message: postalErrorMessage
                                }
                            })}
                        />
                       {errors.postal && <div className="error">{errors.postal.message}</div>}                       
                    </div>
                </div>
                <div className="third-right standard mandatory">
                    <label>Ort<span className="mandatory">*</span></label>
                    <div className="formfieldholder">
                        <input 
                            type="text" 
                            defaultValue={data['city']} 
                            {...register('city', {
                                required: emptyErrorMessage      
                            })}
                        />
                       {errors.city && <div className="error">{errors.city.message}</div>}                         
                    </div>
                </div>
                <div className="submit_container">
                    <div className="formfieldholder">
                        <button type="submit" className="submit">Speichern</button>
                    </div>
                </div>
            </form>
        </div>
        </>
    )

}

export default ChangeShippingForm;