import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from "../services/api.service";
import CheckoutHead from './checkoutpage/CheckoutHead';
import CheckoutForm from './checkoutpage/CheckoutForm';
import CheckoutOverview from './checkoutpage/CheckoutOverview';
import * as GlobalStore from '../store/global/actions';

const CheckoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const shoppingcart = useSelector(state => state.shoppingcart.finalshoppingcart);
    const singlecart = useSelector(state => state.cart);

    let cartmode = 'single';
    let cart;

    if (useshoppingcart) {
        cart = shoppingcart;
        cartmode = 'multi';
    }
    else {
        cart = singlecart;
    }

    const [activeCart, setActiveCart] = useState();
    const [activeStep, setActiveStep] = useState(2);
    const [noProductContent, setNoProductContent] = useState();

    const [error, setError] = useState(null);
    if (error) throw error;

    function noProduct() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                        <h1>Kein Produkt gewählt</h1>
                        <p>Bitte wählen Sie ein Produkt, bevor Sie zur Kasse gehen.</p>
                        <p><Link className='button' to="?path=start">Zurück zur Startseite</Link></p>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (cartmode === 'single') {
            if (cart.category) {
                const API_URL = global.config.express.url + '/cart/info';
                const API_DATA = {
                    'shop-url': global.config.apidata.shopurl,
                    'cart': cart
                }
                ApiService.getData(API_URL, API_DATA).then((response) => {
                    if (typeof (response.apierror) !== 'undefined') {
                        setError(response.apierror);
                    }
                    else {
                        if (response.status === 'error') {
                            dispatch(GlobalStore.setApiError(response.message));
                            navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                        }
                        else {
                            setActiveCart(response.cart);
                        }

                    }
                }).catch((error) => { setError(error) });
            }
            else {
                setNoProductContent(noProduct());
            }
        }
        else {
            if (cart['order-product-bundle-groups'].length) {
                setActiveCart(cart);
            }
        }

    }, [cartmode, cart, dispatch, navigate]);

    if (activeCart) {

        let type = "email";
        activeCart['order-distribution-products'].forEach(p => {
            if (p['order-method-product'].type !== 'email') {
                type = p['order-method-product'].type;
            }
        })

        return (
            <>
                <CheckoutHead activeStep={activeStep} setActiveStep={setActiveStep} />
                <CheckoutForm activeStep={activeStep} setActiveStep={setActiveStep} setActiveCart={setActiveCart} type={type} cart={activeCart} />
                <CheckoutOverview activeStep={activeStep} type={type} cart={activeCart} />
            </>
        );
    }

    else {
        return (
            noProductContent
        );
    }

}

export default CheckoutPage;
