import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';

const ProductOptionsAmount = ({setselect, setCouponActivePrice}) => {

    const dispatch = useDispatch();
    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);
    const amount = useSelector(state => state.productpage.amount);

    function changeStates (amount) {
        dispatch(ProductPageStore.setAmount(amount));
        dispatch(CartStore.setCartQty(amount));
        dispatch(ProductPageStore.setCoupon());  
        dispatch(CartStore.setCartCouponCode(null));                
        setCouponActivePrice(null); 
        setselect('hidden');
        if (typeof(document.getElementsByClassName('gw_overlay')[0]) !== 'undefined'){
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }

    let view = [];
    if (contingent !== 0){
        let maxcontingent = contingent === null || contingent > 10 ? 10 : contingent;
        if (minqty < maxcontingent) {
            for (let i = minqty; i <= maxcontingent; i++) {
                view.push(
                    <button
                        type="button"
                        onClick={() => changeStates(i)}
                        key={i}
                        className={(amount === i ? 'activ' : '')}>{i}
                    </button>
                );             
            }
        }
    }

    return (
        <>
        {view}
        </>
    );

}

export default ProductOptionsAmount;
