import { useEffect, useState } from "react";
import ApiService from "../../../services/api.service";
import { useDispatch, useSelector } from "react-redux";

import * as ShoppingCartStore from '../../../store/shoppingcart/actions';

const CouponCode = ({cart, setActiveCart}) => {


    const dispatch = useDispatch();
    
    
    const [couponcode, setCouponcode] = useState(cart['coupon-code']);
    const [couponcodeState, setCouponcodeState] = useState();
    const [couponcodeMessage, setCouponcodeMessage] = useState();
    
    const shoppingcartcouponcode = useSelector(state => state.shoppingcart.shoppingcartcouponcode);

    function changeInput(e) {
        e.target.value === '' ? removeCode() : setCouponcode(e.target.value)
    }

    function checkCode(couponcodeDeleted = false) {
        if (couponcode){

            if (!couponcodeDeleted){
                cart['coupon-code'] = couponcode;
            }
            else {
                cart['coupon-code'] = null;
            }

            const API_URL = global.config.express.url + '/checkcoupon';
            const API_DATA = {
                'shop-url': global.config.apidata.shopurl,
                'cart': cart,
                'shoppingcart': true,
                'deletedCoupon': couponcodeDeleted
            }

            ApiService.getData(API_URL, API_DATA).then((response) => {        
                if (response.coupon.status === 'success'){
                    setActiveCart(response.cart);
                    if (!couponcodeDeleted){
                        setCouponcodeState(response.coupon.status);
                        dispatch(ShoppingCartStore.setShoppingCartCouponCode(cart['coupon-code']));
                    }
                    else {                           
                        setCouponcodeState(); 
                        dispatch(ShoppingCartStore.setShoppingCartCouponCode(null));
                    }
                    setCouponcodeMessage(response.coupon.message);                    
                }
                else {
                    setCouponcodeState(response.coupon.status);
                    setCouponcodeMessage(response.coupon.message);                    
                }
            });
        }
    };

    function removeCode() {   
        setCouponcode(null)        
        checkCode(true);
    };

    useEffect(() => {
        if (shoppingcartcouponcode){
            setCouponcodeState('success');
            setCouponcodeMessage('YEAH! Dein Aktionscode wurde geprüft.');
        }
    }, [shoppingcartcouponcode]);

  
    if (couponcodeState !== 'success'){
        let couponvalue = couponcode === null ? '' : couponcode;
        return (
            <>
            <div className="coupon">
                <label>Aktionscode zur Hand?</label>
                <input onChange={(e) => changeInput(e)} className="code" name="code" value={couponvalue} />
                <button onClick={() => checkCode()} type="button" className="send-code">Einlösen</button>
                {couponcodeState === 'error' ? <p className="error">{couponcodeMessage}</p> : null}
            </div>
            </>
        )
    }
    
    else {   
        let couponvalue = couponcode === null ? '' : couponcode;
        return (
            <>
            <div className="coupon">
                <label>Aktionscode zur Hand?</label>
                <input readOnly className="code" name="code" value={couponvalue} />
                <button onClick={() => removeCode()} type="button" title="Aktionscode entfernen" className="remove-code">X</button>
                <p className="success">{couponcodeMessage}</p>
            </div>
            </>
        )
    }

}


export default CouponCode;
