const ProductPaymentBox = ({cart}) => {

    let view;

    if (cart['cart-price']['gross-amount'].amount === 0){
        view = <p>Aktionscode</p>           
    }

    else {
        let paymentimage;       
        switch(cart['payment-tupel']['payment-provider']) {
            case 'paypal':
                paymentimage = global.config.express.url + '/paypal.svg';
                break;
            case 'amazon':
                paymentimage = global.config.express.url + '/amazon.svg';
                break;
            default:
                paymentimage = '';
        }
        view =
            <p>
                <img className="hidden-xs" src={paymentimage} width="100" alt={cart['payment-tupel']['payment-name']} />
                <span className="hidden visible-xs">{cart['payment-tupel']['payment-name']}</span>
            </p> 
        
    }   

    return (
        <>
        <div className="pbox">
            <h3>Zahlungsart:</h3>                                
            {view}
        </div>
        </>
    )

}

export default ProductPaymentBox;