import { useRef, useState } from "react";
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import ProductOptionsAmount from './ProductOptionsAmount';

import { useSelector } from 'react-redux';


const ProductFormOptionsAmount = ({setCouponActivePrice}) => {

    const activeProductData = useSelector(state => state.productpage.productdata);
    const activeAmount = useSelector(state => state.productpage.amount);
    const [amountSelect, setAmountSelect] = useState('hidden');

    const wrapperRef = useRef(null);
    HideOpenedSelect(wrapperRef, amountSelect, setAmountSelect);


    function changeState()  {
        setAmountSelect('visible')
        if (window.innerWidth < 768){
            var overlay = document.createElement('div');
            overlay.className = 'gw_overlay';
            document.body.appendChild(overlay);
            document.getElementById('shop').appendChild(overlay);
        }
    }

    function closeOptions() {
        setAmountSelect('hidden');
        if (typeof(document.getElementsByClassName('gw_overlay')[0]) !== 'undefined'){
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }


    return (
        <>
        <div className="input-holder" ref={wrapperRef}>
            <label>Menge:</label>
            <input onClick={() => changeState()} className="selections amount" type="text" value={activeAmount} autoComplete="off" readOnly disabled={activeProductData.contingent === 0 || (activeProductData.contingent !== null && activeProductData['minimum-quantity'] > activeProductData.contingent) ? 'disabled' : ''} />
            <div className={amountSelect + " select_options"}>
                <div className="hl hidden visible-xs"><span className="label">Menge</span><span onClick={() => closeOptions()} className="close">x</span></div>
                <ProductOptionsAmount setCouponActivePrice={setCouponActivePrice} setselect={setAmountSelect} />
            </div>
        </div>
        </>
    )

}

export default ProductFormOptionsAmount;