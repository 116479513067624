import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ShoppingCartStore from '../store/shoppingcart/actions';

const OrderPendingPage = () => {

    const dispatch = useDispatch();
    dispatch(ShoppingCartStore.resetShoppingCart());

    const speech = useSelector(state => state.global.speech);

    return (
        <div className="container nopadding overview">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>Bestellung in Bearbeitung</h1>

                    <p><strong>Wir warten auf {speech !== 'informal' ? 'Ihren' : 'Deinen'} Zahlungseingang!</strong></p>
                    <p>Sobald wir die Zahlung erhalten haben, senden wir {speech !== 'informal' ? 'Ihnen' : 'Dir'} eine E-Mail mit allen weiteren Informationen.</p>
                    <p><br /><Link className='button' to="?path=start">Zurück zur Startseite</Link></p>
                </div>
            </div>
        </div>
    );
}

export default OrderPendingPage;
