import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';


const QRCheckout = () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const identifier = queryParameters.get("transitIdentifier")

    const speech = useSelector(state => state.global.speech);

    const [error, setError] = useState(null);
    if (error) throw error;

    const [counterView, setCounterView] = useState();
    const [response, setResponse] = useState(null);

    const [refresh, setRefresh] = useState(true);

    const [expired, setExpired] = useState(false);

    const getData = (identifier) => {
        const API_URL = global.config.express.url + '/checkoutinfo';
        const API_DATA = {
            'identifier': identifier
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                if (response.data['redirect-url']) {
                    window.location.replace(response.data['redirect-url']);
                    return false;
                }
                let now = new Date().getTime();
                var distance = response.data.expiry * 1000 - now;
                if (distance <= 0) {
                    setExpired(true)
                }
                setResponse(response);

            }
        }).catch((error) => { setError(error) });
    }


    useEffect(() => {
        if (identifier) {
            getData(identifier)
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier]);


    useEffect(() => {
        if (response?.data?.expiry && !expired) {
            let now = new Date().getTime();
            var distance = response.data.expiry * 1000 - now;
            if (distance > 0) {
                setTimeout(() => {
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    seconds = seconds < 10 ? '0' + seconds : seconds;
                    setCounterView(minutes + ':' + seconds);
                    if (distance < 0) {
                        setExpired(true)
                    }
                }, 1000);
            }
            else {
                setExpired(true)
            }

        }
    }, [response, counterView, expired]);


    useEffect(() => {
        if (response?.data && refresh && !expired) {
            !response.data['redirect-url'] && setTimeout(() => {
                setRefresh(false);
                getData(identifier);
                setRefresh(true);
            }, 10000);
        }
    }, [refresh, response, identifier, expired]);




    let cont;
    if (identifier && response) {
        if (expired) {
            cont =
                <div className="container nopadding qr-checkout">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                            <h1>Zeit abgelaufen</h1>
                            <p>Die verfügbare Zeit um die Bestellung zu bezahlen ist leider abgelaufen.</p>
                            <p>Bitte führe{speech === 'informal' ? '' : 'n Sie'} die Bestellung erneut aus.</p>
                        </div>
                    </div>
                </div>
                ;
        }
        else {
            cont =
                <div className="container nopadding qr-checkout">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                            <h1>Bestellung bezahlen</h1>
                            <p>{response.data.description} wartet auf Zahlungseingang!</p>
                            <p>Bitte den nachfolgenden Code scannen:</p>
                            <QRCode className="qrcode" value={response.data['qr-content']} bgColor={"transparent"} color='black' />
                            <p>{response.data.amount} {response.data.currency} &gt; {response.data['exchange-amount']} {response.data['exchange-currency']}</p>
                            <p>Zeit zum Bezahlen: <br />{counterView}</p>
                        </div>
                    </div>
                </div>
                ;
        }
        return cont;
    }

}

export default QRCheckout;
