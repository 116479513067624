export const resetData = data => ({
  type: 'RESET',
  data
});

export const setData = data => ({
  type: 'SET_DATA',
  data
});

export const setProducts = products => ({
  type: 'SET_PRODUCTS',
  products
});

export const setProductData = productdata => ({
  type: 'SET_PRODUCTDATA',
  productdata
});

export const setDistributionProducts = distributionproducts => ({
  type: 'SET_DISTRIBUTIONPRODUCTS',
  distributionproducts
});

export const setPayments = payments => ({
  type: 'SET_PAYMENTS',
  payments
});

export const setGreetingText = greetingtext => ({
  type: 'SET_GREETINGTEXT',
  greetingtext
});

export const setPackaging = packaging => ({
  type: 'SET_PACKAGING',
  packaging
});

export const setShipping = shipping => ({
  type: 'SET_SHIPPING',
  shipping
});

export const setAmount = amount => ({
  type: 'SET_AMOUNT',
  amount
});

export const setCoupon = coupon => ({
  type: 'SET_COUPON',
  coupon
});

export const setAppointmentProducts = appointmentproducts => ({
  type: 'SET_APPOINTMENTPRODUCTS',
  appointmentproducts
});

export const setAppointment = appointment => ({
  type: 'SET_APPOINTMENT',
  appointment
});
