const initialState = {
  amount: 1  
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET': {
      return initialState;
    }

    case 'SET_DATA': {
      return {
        ...state,
        data: action.data,
      };
    }
    case 'SET_PRODUCTS': {
      return {
        ...state,
        products: action.products,
      };
    }
    case 'SET_PRODUCTDATA': {
      return {
        ...state,
        productdata: action.productdata,
      };
    }
    case 'SET_DISTRIBUTIONPRODUCTS': {
      return {
        ...state,
        distributionproducts: action.distributionproducts,
      };
    }
    case 'SET_PAYMENTS': {
      return {
        ...state,
        payments: action.payments,
      };
    }
    case 'SET_GREETINGTEXT': {
      return {
        ...state,
        greetingtext: action.greetingtext,
      };
    }
    case 'SET_PACKAGING': {
      return {
        ...state,
        packaging: action.packaging,
      };
    }
    case 'SET_SHIPPING': {
      return {
        ...state,
        shipping: action.shipping,
      };
    }
    case 'SET_AMOUNT': {
      return {
        ...state,
        amount: action.amount,
      };
    }
    case 'SET_COUPON': {
      return {
        ...state,
        coupon: action.coupon,
      };
    }
    case 'SET_APPOINTMENTPRODUCTS': {
      return {
        ...state,
        appointmentproducts: action.appointmentproducts,
      };
    }
    case 'SET_APPOINTMENT': {
      return {
        ...state,
        appointment: action.appointment,
      };
    }
   
    default: {
      return state;
    }
  }
};

export default Reducer;
