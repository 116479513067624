const initialState = {
  couponcode: null
};


const Reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET': {
      return initialState;
    }

    case 'SET_CART_CATEGORY': {
      return {
        ...state,
        category: action.category,
      };
    }
    case 'SET_CART_CONSUMER': {
      return {
        ...state,
        consumer: action.consumer,
      };
    }
    case 'SET_CART_PRODUCTID': {
      return {
        ...state,
        productid: action.productid,
      };
    }
    case 'SET_CART_BUNDLESKU': {
      return {
        ...state,
        bundlesku: action.bundlesku,
      };
    }
    case 'SET_CART_SKU': {
      return {
        ...state,
        sku: action.sku,
      };
    }
    case 'SET_CART_QTY': {
      return {
        ...state,
        qty: action.qty,
      };
    }
    case 'SET_CART_PERSMSG': {
      return {
        ...state,
        msg: action.msg,
      };
    }
    case 'SET_CART_PACKAGING': {
      return {
        ...state,
        packaging: action.packaging,
      };
    }
    case 'SET_CART_SHIPPING': {
      return {
        ...state,
        shipping: action.shipping,
      };
    }
    case 'SET_CART_COUPONCODE': {
      return {
        ...state,
        couponcode: action.couponcode,
      };
    }
    case 'SET_CART_SUBPRODUCTS': {
      return {
        ...state,
        subproducts: action.subproducts,
      };
    }
    case 'SET_CART_APPOINTMENT': {
      return {
        ...state,
        appointment: action.appointment,
      };
    }
    case 'SET_CART_TEMPLATE': {
      return {
        ...state,
        template: action.template,
      };
    }
    case 'SET_CART_DYNAMICPRICE': {
      return {
        ...state,
        dynamicprice: action.dynamicprice,
      };
    }


    default: {
      return state;
    }
  }
};

export default Reducer;
