import { useRef, useState } from "react";
import HelperService from "../../../services/helper.service";
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import ProductOptionsPackaging from './ProductOptionsPackaging';

import { useSelector } from 'react-redux';


const ProductFormOptionsPackaging = ({setCouponActivePrice}) => {

    const activeProductData = useSelector(state => state.productpage.productdata);
    const activePackaging = useSelector(state => state.productpage.packaging);
    const [packagingSelect, setPackagingSelect] = useState('hidden');

    const wrapperRef = useRef(null);
    HideOpenedSelect(wrapperRef, packagingSelect, setPackagingSelect);

    return (
        <>        
        <div className="input-holder" ref={wrapperRef}>
            <label>Verpackung:</label>
            <input onClick={() => setPackagingSelect('visible')} className="selections packaging" type="text" value={activePackaging.name + (activePackaging.price !== 0 ? ' ' + HelperService.formatPrice(activePackaging.price) : '')} autoComplete="off" readOnly disabled={activeProductData.contingent === 0 || (activeProductData.contingent !== null && activeProductData['minimum-quantity'] > activeProductData.contingent) ? 'disabled' : ''} />
            <div className={packagingSelect + " select_options"}>
                <div className="hl hidden visible-xs"><span className="label">Verpackung</span><span className="close">x</span></div>
                <ProductOptionsPackaging type="select" mobile="no" setCouponActivePrice={setCouponActivePrice} setselect={setPackagingSelect} />
            </div>
        </div>
        </>
    )

}

export default ProductFormOptionsPackaging;