import { useSelector } from 'react-redux';

const ProductContingent = () => {

    const contingent = useSelector(state => state.productpage.productdata.contingent);
    const minqty = useSelector(state => state.productpage.productdata['minimum-quantity']);

    let contingentHtml;
    if (contingent !== null ){
        let soldout = contingent === 0 || minqty > contingent ? ' soldout' : '';
        let contingentText = contingent > 0 && minqty <= contingent ? 'Nur noch ' + contingent + ' Stück verfügbar' : "nicht mehr verfügbar";
        contingentHtml = contingentText = contingent < 100 ? <span className={"count" + soldout}>{contingentText}</span> : '';
    }

    return (
        <>
        {contingentHtml}
        </>
    );

}

export default ProductContingent;
