import { useNavigate } from 'react-router-dom'
import ApiService from "../../../services/api.service";

const ProductBotButton = () => {

    const navigate = useNavigate();

    function clickButton(e){     
        
        const API_URL = global.config.express.url + '/botbutton';
        const API_DATA = {
            'url': window.location.href,
            'agent': navigator.userAgent
        }

        ApiService.getData(API_URL, API_DATA).then((response) => {  
            if (response){
                navigate('?path=bestellung-fehlgeschlagen', {replace: true});
            }      
        });
        
    }    

    return (
        <button type="button" className="bbut" onClick={(e) => clickButton(e)}>Click</button>
    );

}

export default ProductBotButton;
