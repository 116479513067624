const ProductImageBox = ({product}) => {

    let productname = product['name'];
    let productimage;

    if (product['product-logo'] !== null){
        let imgsrc = global.config.apidata.url + '/' + product['product-logo']['relative-path'];
        let imgalt = product['product-logo'].alt;
        productimage =  <img src={imgsrc} alt={imgalt} />
    }

    return (
        <>
        <div className="productbox">
            <span className="pic">
            {productimage}
            </span>
            <div className="inner t-center">
                <h2>{productname}</h2>
            </div>
        </div>
        </>
    )

}

export default ProductImageBox;