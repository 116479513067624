import HelperService from "../../../services/helper.service";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';

const ProductOptionsChoose = ({ type, setselect, setCouponActivePrice }) => {

    const dispatch = useDispatch();
    const products = useSelector(state => state.productpage.products);
    const productid = useSelector(state => state.productpage.productdata.id);

    function changeStates(product) {

        dispatch(ProductPageStore.setProductData({
            'id': product.id,
            'name': product.name,
            'description': product.description,
            'sku': product.sku,
            'value': product['product-price']['gross-amount-value'].amount,
            'price': product['product-price']['gross-amount'].amount,
            'contingent': product['available-contingent'],
            'minimum-quantity': product['minimum-quantity'],
            'templates': product['media-templates'],
        }));

        dispatch(ProductPageStore.setAmount(product['minimum-quantity']));
        dispatch(CartStore.setCartSku(product.sku));
        dispatch(CartStore.setCartQty(product['minimum-quantity']));
        dispatch(ProductPageStore.setCoupon());
        dispatch(CartStore.setCartCouponCode(null));
        setCouponActivePrice(null);

        if (setselect) {
            setselect('hidden');
            if (typeof (document.getElementsByClassName('gw_overlay')[0]) !== 'undefined') {
                document.getElementsByClassName('gw_overlay')[0].remove();
            }
        }
    }

    if (products) {
        const productCooseBoxes = products.map((product) => (
            <button
                type="button"
                onClick={() => changeStates(product)}
                key={product.id}
                className={(productid === product.id ? 'activ' : '')}>{HelperService.formatPrice(product['product-price']['gross-amount-value'].amount)}
            </button>
        ));


        let view;

        if (type === 'select') {
            view =
                <>
                    {productCooseBoxes}
                </>
        }
        else {
            view =
                <div className="hidden-md hidden-sm hidden-xs">
                    <h3>Optionen</h3>
                    <div className="checkboxes options">
                        {productCooseBoxes}
                    </div>
                </div>
        }


        return (
            <>
                {view}
            </>
        );
    }

}

export default ProductOptionsChoose;
