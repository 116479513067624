import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import ApiService from "../../../services/api.service";
import * as GlobalStore from '../../../store/global/actions';

const ConfirmButton = ({cart, identifier}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function confirmOrder(e) {
        e.target.setAttribute('disabled', true);
        e.target.classList.add('wait');
        e.target.firstChild.data = 'Bestellung wird verarbeitet';
      
        const API_URL = global.config.express.url + '/order/execute';
        const API_DATA = {
            'transit-identifier': identifier,
            'shop-url': global.config.apidata.shopurl,
            'cart': cart,
            'framepage': window.location.origin + window.location.pathname + '?mode=react'
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (response.status === 'success'){
                if (response.url){
                    window.location.replace(response.url);    
                }
            }
            else {
                dispatch(GlobalStore.setApiError(response.message));
                navigate('?path=bestellung-fehlgeschlagen', {replace: true});
            }
        });

    }

    return (
        <button onClick={(e) => confirmOrder(e)} className={'confirmOrder button'}>Jetzt kaufen</button>
    )

}

export default ConfirmButton;