class HelperService {
    formatPrice(data) {
        let locale = 'de';
        let options = {style: 'currency', currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2};
        let formatter = new Intl.NumberFormat(locale, options);
        return (
            formatter.format(data)
        )
    }
    

    generateOrderTable(products, shoppingcart = false) {        
      
        let productbundles = [];

        products.forEach((product, index) => {   
            
            let singleprice = product['order-product-bundles'][0]['price-total']['gross-amount-value']['amount'];
            let totalprice = product['price-total']['gross-amount-value']['amount'];
            let subproducttext = '';    

            if (product['configuration-type'] && product['configuration-type'] !== 'choose'){            
                let subproducts = [];
                let count = 0;
                product['order-product-bundles'][0]['order-products'].forEach((product) => {
                    let arraykey = count === 0 ? count : count -1;                
                    if (typeof(subproducts[arraykey]) !== 'undefined' && subproducts[arraykey].sku === product.sku) {
                        subproducts[arraykey].count++;                    
                    }              
                    else {
                        subproducts.push({
                                'sku': product.sku,
                                'name': product.name,
                                'count': 1                                              
                        });
                        count++;
                    }
                });
                let sc = 0;
                subproducts.forEach((p) => {
                    subproducttext += (sc !== 0 ? ' | ' : '') + p.count + 'x ' + p.name;
                    sc++
                })
                subproducttext = 'Enthält: ' + subproducttext
            }
                        
            let productimage;
            if (product['product-logo'] !== null){
                let imgsrc = global.config.apidata.url + '/' + product['product-logo']['relative-path'];
                let imgalt = product['product-logo'].alt;
                productimage =  <img className={shoppingcart ? "hidden-xs" : "hidden-lg hidden-xs"} src={imgsrc} alt={imgalt} />
            }        
          
            
            if (product['appointment-based']) {             
                
                let appointmenttext = null;

                // Terminbasiertes Produkt ohne Konfigurationstyp
                if (!product['configuration-type']) {
                    let appointmentname = product['order-product-bundles'][0]['order-products'][0].appointment.name;
                    appointmenttext = 'Termin: ' + appointmentname;                    
                }

                // Terminbasiertes Produktpaket
                else {
                    let appointmentname = product['order-product-bundles'][0]['order-products'][0].appointment.name;
                    appointmenttext = 'Termin: ' + appointmentname;     
                }

                let check = productbundles.findIndex((obj => obj.sku === product.sku && obj.subproducttext === subproducttext && obj.appointmenttext === appointmenttext ));
                if (check !== -1) {                    
                    productbundles[check].count++;                    
                    productbundles[check].quantity = product.quantity + productbundles[check].quantity;                    
                    productbundles[check].totalprice = singleprice * productbundles[check].quantity;                    
                }              
                else {
                    productbundles.push({
                        'index': index,
                        'configuration-type': product['configuration-type'],
                        'sku': product.sku,
                        'name': product.name,
                        'quantity': product.quantity,
                        'image': productimage ? productimage : null,
                        'subproducttext': subproducttext,
                        'appointmenttext': appointmenttext,
                        'singleprice': singleprice,
                        'totalprice': totalprice    
                    });                  
                }
            }
            

            else {
                if (product['configuration-type'] === 'choose') {
                    product.name = product['order-product-bundles'][0]['order-products'][0].name;
                }                

                let check = productbundles.findIndex((obj => obj.name === product.name && obj.sku === product.sku && obj.subproducttext === subproducttext));
                if (check !== -1) {
                    productbundles[check].count++;                    
                    productbundles[check].quantity = product.quantity + productbundles[check].quantity;                    
                    productbundles[check].totalprice = singleprice * productbundles[check].quantity;                    
                }              
                else {
                    productbundles.push({
                        'index': index,
                        'configuration-type': product['configuration-type'],
                        'sku': product.sku,
                        'name': product.name,
                        'quantity': product.quantity,
                        'image': productimage ? productimage : null,
                        'subproducttext': subproducttext,
                        'singleprice': singleprice,
                        'totalprice': totalprice
                    });
                }
            }
            

        });

        productbundles.sort(function(a, b) { 
            return ( a.id - b.id  ||  a.name.localeCompare(b.name));
        });

        return productbundles;
    }   

    getCartProductCount(shoppingcartproducts){
        let count = 0;
        if(shoppingcartproducts.length){
            shoppingcartproducts.forEach((p) => {
                count += p.qty
            })
        }
        return count;
    }


}

const exportData = new HelperService();
export default exportData;
