import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CheckoutHead = ({ activeStep, setActiveStep }) => {

    const navigate = useNavigate();
    const unidyRequested = useSelector(state => state.global.unidyrequested);

    function goBackToProduct() {
        if (unidyRequested) {
            navigate(-2);
        }
        else {
            navigate(-1);
        }
    }

    function goBackToStepTwo() {
        setActiveStep(2);
    }

    return (
        <>
            <div className="container t-center">
                <div className="checkoutsteps">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <span onClick={() => goBackToProduct()} className="step">1</span>
                            <span className="steplabel">Auswahl</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <span onClick={() => goBackToStepTwo()} className={activeStep === 2 ? 'step active' : 'step'}>2</span>
                            <span className="steplabel">Adressdaten</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <span className={activeStep === 3 ? 'step active' : 'step'}>3</span>
                            <span className="steplabel hidden-xs">Übersicht &amp; Bezahlung</span>
                            <span className="steplabel hidden visible-xs">Bezahlen</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CheckoutHead;