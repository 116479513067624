import { useRef, useState } from "react";
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import ProductSelectOptionsAppointment from "./ProductSelectOptionsAppointment";

import { useSelector } from 'react-redux';


const ProductFormOptionsAppointment = ({setCouponActivePrice}) => {

    const activeAppointment = useSelector(state => state.productpage.appointment);
    const [appointmentSelect, setAppointmentSelect] = useState('hidden');

    const wrapperRef = useRef(null);
    HideOpenedSelect(wrapperRef, appointmentSelect, setAppointmentSelect);

    function changeState()  {
        setAppointmentSelect('visible')
        if (window.innerWidth < 768){
            var overlay = document.createElement('div');
            overlay.className = 'gw_overlay';
            document.body.appendChild(overlay);
            document.getElementById('shop').appendChild(overlay);
        }
    }

    function closeOptions() {
        setAppointmentSelect('hidden');
        if (typeof(document.getElementsByClassName('gw_overlay')[0]) !== 'undefined'){
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }

    if (activeAppointment){
        return (
            <>
            <div className="input-holder" ref={wrapperRef}>
                <label>Terminauswahl:</label>
                <input onClick={() => changeState()} className="selections appointment" type="text" value={activeAppointment.name} autoComplete="off" readOnly />
                <div className={appointmentSelect + " select_options"}>
                    <div className="hl hidden visible-xs"><span className="label">Terminauswahl</span><span onClick={() => closeOptions()} className="close">x</span></div>
                    <ProductSelectOptionsAppointment type="select" mobile="no" setCouponActivePrice={setCouponActivePrice} setselect={setAppointmentSelect} />
                </div>
            </div>
            </>
        )
    }

}

export default ProductFormOptionsAppointment;