import * as React from 'react';

let choosentheme = typeof(global.config.colortheme) !== 'undefined' && global.config.colortheme !== '' && global.config.colortheme !== null ? global.config.colortheme.charAt(0).toUpperCase() + global.config.colortheme.slice(1) : 'Default';

const Theme = () => {
   return React.lazy(() => import('./themes/' + choosentheme));
};

const ColorTheme = ({ children }) => {  
    return (
    <>
        <React.Suspense>
        <Theme />
        </React.Suspense>
        {children}
    </>
    )
};

export default ColorTheme;