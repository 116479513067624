export const resetData = data => ({
    type: 'RESET',
    data
});

export const setShowCategories = showcategories => ({
  type: 'SET_SHOW_CATEGORIES',
  showcategories
});

export const setCategoryState = categorystate => ({
  type: 'SET_CATEGORYSTATE',
  categorystate
});

export const setActiveCategory = activecategory => ({
  type: 'SET_ACTIVE_CATEGORY',
  activecategory
});

export const setActiveCategoryName = activecategoryname => ({
  type: 'SET_ACTIVE_CATEGORYNAME',
  activecategoryname
});

export const setCategories = allcategories => ({
  type: 'SET_CATEGORIES',
  allcategories
});

export const setProducts = allproducts => ({
  type: 'SET_PRODUCTS',
  allproducts
});
