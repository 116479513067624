import HelperService from "../../../services/helper.service";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';

const ProductOptionsShipping = ({ type, mobile, setCouponActivePrice, setselect = '' }) => {

    const dispatch = useDispatch();
    const distribution = useSelector(state => state.productpage.distributionproducts);
    const packagingid = useSelector(state => state.productpage.packaging.id);
    const shippingid = useSelector(state => state.productpage.shipping.id);

    function changeShippingStates(id, type, description, price) {

        dispatch(ProductPageStore.setShipping({
            'id': id,
            'name': description,
            'price': price,
            'type': type
        }));
        dispatch(CartStore.setCartShipping(id));
        dispatch(ProductPageStore.setCoupon());
        dispatch(CartStore.setCartCouponCode(null));
        setCouponActivePrice(null);
        if (setselect) {
            setselect('hidden');
        }
    }

    let shippingBoxes = [];
    distribution.forEach((packaging) => {
        if (packaging['shipping-packaging'].id === packagingid) {
            let shippingprovider = packaging['shipping-packaging']['shipping-provider-products'];
            shippingprovider.forEach((provider) => {
                provider['shipping-method-products'].forEach((method) => {
                    let id = method.id;
                    let type = method.type;
                    let description = method.description;
                    let rawprice = method['price']['gross-amount']['amount'];
                    let price = rawprice !== 0 ? HelperService.formatPrice(rawprice) : '';
                    let selectoption;
                    let image;
                    if (type !== 'select') {
                        if (method['distribution-icon']) {
                            let imagepath = global.config.apidata.url + '/' + method['distribution-icon']['relative-path'];
                            let imagealt = method['distribution-icon']['alt'];
                            image = <img className="shippingicon" src={imagepath} alt={imagealt} />
                        }
                        selectoption = mobile !== 'yes' ? description : description + ' ' + price;
                    }
                    else {
                        selectoption = description + ' ' + price;
                    }
                    shippingBoxes.push(
                        <button
                            type="button"
                            onClick={() => changeShippingStates(id, type, description, rawprice)}
                            className={(shippingid === id ? 'activ' : '')}
                            key={id}
                        >
                            {image}
                            {selectoption}
                        </button>
                    )
                });
            });
        }
    });

    let view;

    if (type === 'select') {
        view =
            <>
                {shippingBoxes}
            </>
    }
    else {
        let classes = 'boxview';
        if (mobile !== 'yes') {
            classes = "hidden-md hidden-sm hidden-xs";
        }
        view =
            <div className={classes}>
                <h3>Versand</h3>
                <div className="checkboxes shipping">
                    {shippingBoxes}
                </div>
            </div>
    }
    return (
        <>
            {view}
        </>
    );

}

export default ProductOptionsShipping;
