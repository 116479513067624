import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiService from "../services/api.service";
import OrderBoxes from './ordersuccesspage/elements/OrderBoxes';
import OrderPrintButton from './ordersuccesspage/elements/OrderPrintButton';


const OrderInfoPage = () => {
   
    const [successCart, setSuccessCart] = useState();
    const [successDownload, setSuccessDownload] = useState();
    const [productCount, setProductCount] = useState(false);
    const [additionalText, setAdditionalText] = useState();

    const speech = useSelector(state => state.global.speech);

    const queryParameters = new URLSearchParams(window.location.search);
    const identifier = queryParameters.get("transitIdentifier");
    const singlecode = queryParameters.get("code");
    
    const [error,setError] = useState(null);
    if (error) throw error;

    useEffect(() => {   
        if (identifier){     
            const API_URL = global.config.express.url + '/cart/details';
            const API_DATA = {
                'transit-identifier': identifier,
                'code': singlecode,
                'shop-url': global.config.apidata.shopurl
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof(response.apierror) !== 'undefined'){
                    setError(response.apierror);
                }
                else {
                    setSuccessCart(response.cart);
                    setSuccessDownload(response.download);
                    setProductCount(response.productcount);
                    setAdditionalText(response.cart['order-product-bundle-groups'][0]['order-product-bundles'][0]['order-products'][0]['additional-text'])
                }
            }).catch((error) => { setError(error) });
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier, singlecode]);

    let cont;
    if (successCart){
        if (productCount > 1){
            cont = 
                <div className="container nopadding t-center">      
                    <h1>Bestellinformationen</h1>      
                    <p>{additionalText}</p>
                    <p>{speech === 'informal' ? 'Deinen' : 'Ihren'} Code finde{speech === 'informal' ? 'st du' : 'n Sie'} direkt unter dem Barcode, den {speech === 'informal' ? 'du' : 'Sie'} soeben eingescannt {speech === 'informal' ? 'hast' : 'haben'}.</p>
                    <p style={{'fontSize': '14px'}}><br />Beispielansicht:<br /><span className="codeexample"></span></p>            
                </div>
            ;
        }
        else {
            cont =
                <div className="container nopadding t-center">            
                    <h1>Bestellinformationen</h1>      
                    <p>{additionalText}</p>
                    <h2>Hier ist {speech === 'informal' ? 'dein' : 'Ihr'} Produkt</h2>
                    <div className="row orderboxes">
                    <OrderBoxes products={successCart['order-product-bundle-groups']} />
                    </div>
                    <div className="row">
                        <br /><br />
                        <OrderPrintButton link={successDownload} productCount={productCount} />
                    </div> 
                </div>
            ;
        }
    }

    return cont;   

}

export default OrderInfoPage;
