import { useSelector } from 'react-redux';
import ProductOptionsAppointmentElement from './ProductOptionsAppointmentElement';

const ProductOptionsAppointment = () => {

    const products = useSelector(state => state.productpage.products);

    if (products){       

        let formfields = [];
        products.forEach((p, i) => {
            formfields.push(
                <ProductOptionsAppointmentElement key={i} subnum={i} product={p} />
            )
        });               
        
        return (
            <>
            <div className="included-products">
                <h3>Dieser Termin beinhaltet:</h3>
                <form className="config-selection">
                {formfields}
                </form>
            </div>
            </>
        );
    }
        
}

export default ProductOptionsAppointment;
