import { useSelector } from 'react-redux';
import ProductBox from './elements/ProductBox';

const ProductList = () => {

    const products = useSelector(state => state.startpage.allproducts);
    const showcats = useSelector(state => state.startpage.showcategories);
    const categorystate = useSelector(state => state.startpage.categorystate);

    if (products){        
        const productBoxes = products.map((product) => (
            <ProductBox key={product.id} product={product} />
        ));
        let productList = showcats && categorystate === 'visible' ? <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 productlist"><div className="row">{productBoxes}</div></div> : <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 productlist"><div className="row flex centered">{productBoxes}</div></div>
        return (
            productList
        );                
    }
}

export default ProductList;
