import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import ColorTheme from './components/colorthemes/ColorTheme';


const root = ReactDOM.createRoot(document.getElementById('shop'));
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <ColorTheme>
        <Router>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Router>
    </ColorTheme>
  </Provider>
  //</React.StrictMode>
);
