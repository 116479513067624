import { useDispatch, useSelector } from 'react-redux';
import ApiService from "../services/api.service";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartContent from './shoppingcartpage/ShoppingCartContent';
import * as ShoppingCartStore from '../store/shoppingcart/actions';
import * as GlobalStore from '../store/global/actions';

const ShoppingCartPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shoppingcartproducts = useSelector(state => state.shoppingcart.shoppingcartproducts);
    const finalshoppingcart = useSelector(state => state.shoppingcart.finalshoppingcart);
    const shoppingcartcouponcode = useSelector(state => state.shoppingcart.shoppingcartcouponcode);

    const [activeCart, setActiveCart] = useState(finalshoppingcart);
    const [noProductContent, setNoProductContent] = useState(noProducts());

    const [error, setError] = useState(null);
    if (error) throw error;

    function noProducts() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                        <h1>Keine Produkte im Warenkorb</h1>
                        <p>Bitte wählen Sie ein Produkt, bevor Sie zum Warenkorb gehen.</p>
                        <p><Link className='button' to="?path=start">Zurück zur Startseite</Link></p>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (shoppingcartproducts.length) {
            const API_URL = global.config.express.url + '/cart/info';
            const API_DATA = {
                'shop-url': global.config.apidata.shopurl,
                'cart': shoppingcartproducts,
                'shoppingcartcouponcode': shoppingcartcouponcode
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    if (response.status === 'error') {
                        dispatch(GlobalStore.setApiError(response.message));
                        navigate('?path=bestellung-fehlgeschlagen', { replace: true });
                    }
                    else {
                        setActiveCart(response.cart);
                        dispatch(ShoppingCartStore.setFinalShoppingCart(response.cart));
                    }

                }
            }).catch((error) => { setError(error) });
        }
        else {
            setNoProductContent(noProducts());
        }
    }, [dispatch, navigate, shoppingcartproducts, shoppingcartcouponcode]);


    if (activeCart && activeCart['order-product-bundle-groups'].length !== 0) {
        return (
            <>
                <div className="container nopadding first"><p><Link className='back' to="?path=start">Zurück zur Übersicht</Link></p></div>
                <div className="container nopadding overview shoppingcart">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                            <h1 className='nomargintop'>Warenkorb</h1>
                        </div>
                        {<ShoppingCartContent cart={activeCart} setActiveCart={setActiveCart} />}
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            noProductContent
        );
    }

}

export default ShoppingCartPage;
