import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from "../services/api.service";
import HelperService from "../services/helper.service";
import ProductImages from './productpage/ProductImages';
import ProductOptions from './productpage/ProductOptions';
import ProductSteps from './productpage/ProductSteps';
import ProductContent from './productpage/ProductContent';
import PageNotFound from './PageNotFound';

import { useDispatch, useSelector } from 'react-redux';
import * as ProductPageStore from '../store/productpage/actions';
import * as CartStore from '../store/cart/actions';
import * as GlobalStore from '../store/global/actions';

const ProductPage = () => {

    const dispatch = useDispatch();

    const [error, setError] = useState(null);
    if (error && error !== '404') {
        throw error;
    }

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const shoppingcartproducts = useSelector(state => state.shoppingcart.shoppingcartproducts);

    useEffect(() => {

        dispatch(ProductPageStore.resetData());

        dispatch(CartStore.setCartCouponCode(null));
        dispatch(ProductPageStore.setCoupon());

        const queryParameters = new URLSearchParams(window.location.search);
        const predefinedcode = queryParameters.get("predefinedcode");
        if (predefinedcode) {
            dispatch(GlobalStore.setPredefinedCode(predefinedcode));
        }
        const path = queryParameters.get("path");
        let finalpath = path.split("/");

        let multishopidentifier = null;
        let category = finalpath[0];
        let product = finalpath[1];

        if (finalpath[2]) {
            multishopidentifier = finalpath[0];
            category = finalpath[1];
            product = finalpath[2];
        }

        const API_URL = global.config.express.url + '/product';
        const API_DATA = {
            'shop-url': global.config.apidata.shopurl,
            'multishopidentifier': multishopidentifier,
            'category': category,
            'product': product,
            'backendshop': global.config.backendshop,
            'exchange': global.config.exchange
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                dispatch(ProductPageStore.setData(response));
                dispatch(GlobalStore.setSpeech(response.speech));
                dispatch(GlobalStore.setUnidyRequested(false));
                dispatch(GlobalStore.setUnidyData());
                dispatch(GlobalStore.setUseShoppingCart(response['shopping-cart']));
            }
        }).catch((error) => {
            setError(error)
        });
    }, [dispatch]);

    let content =
        error === '404'
            ? <PageNotFound />
            : <>
                <div className="container nopadding first">
                    <p className="rel">
                        <Link className='back' to="?path=start">Zurück zur Übersicht</Link>
                        {useshoppingcart ? <Link to="?path=warenkorb" className='carticon'><span>{HelperService.getCartProductCount(shoppingcartproducts)}</span></Link> : ''}
                    </p>
                </div>
                <div className="container nopadding">
                    <div className="product">
                        <div className="row">
                            <ProductImages />
                            <ProductOptions />
                        </div>
                    </div>
                </div>
                <ProductSteps />
                <ProductContent />
            </>

    return content;
}

export default ProductPage;
