import { useSelector } from 'react-redux';
import HelperService from "../../../services/helper.service";

const ProductCouponInfo = () => {

    const discount = useSelector(state => state.productpage.coupon);

    let discountHtml;
    if (discount && typeof(discount.value) !== 'undefined'){
        discountHtml = <div className="couponinfo">Aktionscode: <strong>{HelperService.formatPrice(discount.value)}</strong> Zusatzrabatt</div>;
    }

    return (
        <>
        {discountHtml}
        </>
    );

}

export default ProductCouponInfo;
