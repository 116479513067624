import { useDispatch, useSelector } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';


const ProductFormOptionsDynamicPrice = ({ setCouponActivePrice, setDynamicPrice }) => {

    const dispatch = useDispatch();

    const activeProductData = useSelector(state => state.productpage.productdata);
    const changeState = (e) => {

        let val = e.target.value === '' ? '0' : e.target.value;

        activeProductData.price = parseInt(val);
        activeProductData.value = parseInt(val);

        dispatch(ProductPageStore.setProductData(activeProductData));
        dispatch(CartStore.setCartDynamicPrice(parseFloat(val).toFixed(2)));
        setDynamicPrice(val === '0' ? null : parseFloat(val).toFixed(2));

        dispatch(CartStore.setCartCouponCode(null));
        dispatch(ProductPageStore.setCoupon());
        setCouponActivePrice(null);
    }

    const handleCurrency = (e) => {
        let val = e.target.value === '' ? '0' : e.target.value;
        e.target.value = parseFloat(val).toFixed(2);
    }

    return (
        <>
            <div className="input-holder price">
                <label>Wert:</label>
                <input onChange={(e) => changeState(e)} onBlur={(e) => { handleCurrency(e) }} className="price" type="number" step="0.01" defaultValue={parseFloat(activeProductData.price).toFixed(2)} autoComplete="off" />
            </div>
        </>
    )

}

export default ProductFormOptionsDynamicPrice;