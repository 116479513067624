const initialState = {
    speech: 'informal',
    useshoppingcart: false,
    unidyrequested: false
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SPEECH': {
            return {
                ...state,
                speech: action.speech,
            };
        }
        case 'SET_USESHOPPINGCART': {
            return {
                ...state,
                useshoppingcart: action.useshoppingcart,
            };
        }
        case 'SET_PREDEFINEDCODE': {
            return {
                ...state,
                predefinedcode: action.predefinedcode,
            };
        }
        case 'SET_APIERROR': {
            return {
                ...state,
                apierror: action.apierror,
            };
        }
        case 'SET_UNIDYREQUESTED': {
            return {
                ...state,
                unidyrequested: action.unidyrequested,
            };
        }
        case 'SET_UNIDYDATA': {
            return {
                ...state,
                unidydata: action.unidydata,
            };
        }
        default: {
            return state;
        }
    }
};

export default Reducer;
