const initialState = {
  showcategories: false,
  categorystate: 'visible',
  activecategory: 'all',
  activecategoryname: 'Alle Produkte',
  allcategories: [],
  allproducts: []
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET': {
        return initialState;
    }

    case 'SET_SHOW_CATEGORIES': {
      return {
        ...state,
        showcategories: action.showcategories,
      };
    }
    case 'SET_CATEGORYSTATE': {
      return {
        ...state,
        categorystate: action.categorystate,
      };
    }
    case 'SET_ACTIVE_CATEGORY': {
      return {
        ...state,
        activecategory: action.activecategory,
      };
    }
    case 'SET_ACTIVE_CATEGORYNAME': {
      return {
        ...state,
        activecategoryname: action.activecategoryname,
      };
    }
    case 'SET_CATEGORIES': {
      return {
        ...state,
        allcategories: action.allcategories,
      };
    }
    case 'SET_PRODUCTS': {
      return {
        ...state,
        allproducts: action.allproducts,
      };
    }
    default: {
      return state;
    }
  }
};

export default Reducer;
