import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import OrderOverviewContent from './orderviewpage/OrderOverviewContent';


const OrderOverviewPage = () => {
   
    const [overviewCart, setOverviewCart] = useState();
    const [overviewScript, setOverviewScript] = useState();

    const queryParameters = new URLSearchParams(window.location.search)
    const identifier = queryParameters.get("transitIdentifier")     
    
    const [error,setError] = useState(null);
    if (error) throw error;

    useEffect(() => {   
        if (identifier){    
            const API_URL = global.config.express.url + '/cart/details';
            const API_DATA = {
                'transit-identifier': identifier,
                'shop-url': global.config.apidata.shopurl
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof(response.apierror) !== 'undefined'){
                    setError(response.apierror);
                }
                else {
                    setOverviewCart(response.cart);
                    setOverviewScript(response.script);
                }
            }).catch((error) => { setError(error) });
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier]);

    if (identifier){
        return (
            <div className="container nopadding overview">
                <div className="row">
                    <OrderOverviewContent cart={overviewCart} scriptcontent={overviewScript} identifier={identifier} />
                </div>
            </div>
        );
    }    

}

export default OrderOverviewPage;
