import { useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../../store/productpage/actions';
import * as CartStore from '../../../store/cart/actions';
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import HelperService from "../../../services/helper.service";
import ProductTemplateButton from "./ProductTemplateButton";

const ProductOptionsConfigureElement = ({ subnum, product }) => {

    const [amountSelect, setAmountSelect] = useState('hidden');
    const dispatch = useDispatch();
    const activeProductData = useSelector(state => state.productpage.productdata);
    const activeCartSubproducts = useSelector(state => state.cart.subproducts);

    const wrapperRef = useRef();
    HideOpenedSelect(wrapperRef, amountSelect, setAmountSelect);

    function setNewPrice() {
        let data = activeProductData;
        let value = 0;
        let price = 0;
        data.subproducts.forEach(subproduct => {
            value += subproduct.amount * subproduct.value;
            price += subproduct.amount * subproduct.price;
        });
        data.value = value;
        data.price = price;
        let newData = { ...activeProductData, ...data };
        dispatch(ProductPageStore.setProductData(newData));
    }

    function setSubAmount(i) {
        let data = activeProductData;
        data.subproducts[subnum].amount = i;
        let newData = { ...activeProductData, ...data };
        dispatch(ProductPageStore.setProductData(newData));

        let cartData = activeCartSubproducts;
        cartData[subnum].amount = i;
        let newCartData = { ...activeCartSubproducts, ...cartData };
        dispatch(CartStore.setCartSubproducts(newCartData));

        closeOptions();
        setNewPrice();
    }

    function changeState() {
        setAmountSelect('visible')
        if (window.innerWidth < 768) {
            var overlay = document.createElement('div');
            overlay.className = 'gw_overlay';
            document.body.appendChild(overlay);
            document.getElementById('shop').appendChild(overlay);
        }
    }

    function closeOptions() {
        setAmountSelect('hidden');
        if (typeof (document.getElementsByClassName('gw_overlay')[0]) !== 'undefined') {
            document.getElementsByClassName('gw_overlay')[0].remove();
        }
    }

    if (product) {
        const p = product;
        let amount = p['available-contingent'] >= 10 || p['available-contingent'] === null ? 10 : p['available-contingent'];
        let selection = [];
        if (p['arbitrary']) {
            for (let i = p['minimum-quantity']; i <= amount; i++) {
                selection.push(
                    <button onClick={() => setSubAmount(i)} type="button" key={i} className={i === p['minimum_quantity'] ? "activ" : ''} >{i}</button>
                )
            }
        }

        return (
            <>
                <div className="subproductholder">
                    <span className="productname">
                        {p.name} {HelperService.formatPrice(activeProductData.subproducts[subnum].price)}<br />
                        <ProductTemplateButton productId={activeProductData.subproducts[subnum].id} templateCount={activeProductData.subproducts[subnum].templates.length} subnum={subnum} />
                    </span>
                    <div className="input-holder subproduct">
                        <label>Menge:</label>
                        <input onClick={() => changeState()} className="selections" type="text" value={activeProductData.subproducts[subnum].amount} autoComplete="off" readOnly />
                        <div className={amountSelect + " select_options options_list"} ref={wrapperRef}>
                            <div className="hl hidden visible-xs"><span className="label">Menge</span><span onClick={() => closeOptions()} className="close">x</span></div>
                            {selection}
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default ProductOptionsConfigureElement;
