import { useEffect } from "react";

const HideOpenedSelect = (ref, state, setState) => {

    useEffect(() => {    
        if (state === 'visible'){
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setState('hidden');
                    if (typeof(document.getElementsByClassName('gw_overlay')[0]) !== 'undefined'){
                        document.getElementsByClassName('gw_overlay')[0].remove();
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }  
    }, [ref, state, setState]);

  }

  export default HideOpenedSelect;