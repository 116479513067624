import { useSelector } from 'react-redux';

const OrderText = ({ cart, productCount }) => {

    const type = cart['order-distribution-products'][0]['order-method-product'].type;
    const speech = useSelector(state => state.global.speech);

    const morethanone = productCount > 1 ? true : false;

    let text;

    if (type === 'email') {
        text =
            <>
                <h2>{speech === 'informal' ? 'Dein' : 'Ihr'}{morethanone ? 'e' : ''} digitale{morethanone ? 'n' : 's'} Produkt{morethanone ? 'e' : ''}</h2>
                <p>Wir haben {speech === 'informal' ? 'dir' : 'Ihnen'} {morethanone ? 'die Produkte' : 'das Produkt'} per E-Mail gesendet.</p>
                <p>Bitte öffne{speech === 'informal' ? ' dein' : 'n Sie Ihr'} E-Mail-Postfach und klicke{speech === 'informal' ? '' : 'n'} auf das PDF im Anhang der E-Mail.</p>
                <p>{speech === 'informal' ? 'Du kannst' : 'Sie können'} {morethanone ? 'die Produkte' : 'das Produkt'} nun bequem ausdrucken oder weiterleiten.</p>
                <p>Wir wünschen {speech === 'informal' ? 'dir' : 'Ihnen'} viel Spaß mit {morethanone ? (speech === 'informal' ? 'deinen Produkten' : 'Ihren Produkten') : (speech === 'informal' ? 'deinem Produkt' : 'Ihrem Produkt')}.</p>
            </>
    }
    else {
        text =
            <>
                <h2>{speech === 'informal' ? 'Dein' : 'Ihr'}{morethanone ? 'e' : ''} Produkt{morethanone ? 'e' : ''}</h2>
                <p>Wir senden {speech === 'informal' ? 'dir' : 'Ihnen'} {morethanone ? 'die Produkte' : 'das Produkt'} per Post und wünschen {speech === 'informal' ? 'dir' : 'Ihnen'} viel Spaß damit.</p>
            </>
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
            {text}
        </div>
    )

}

export default OrderText;