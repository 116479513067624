import { useRef, useState } from "react";
import ApiService from "../../../services/api.service";
import * as CartStore from '../../../store/cart/actions';
import { useDispatch, useSelector } from "react-redux";


const ProductTemplateButton = ({ productId, templateCount, subnum = false, notype = false }) => {

    const dispatch = useDispatch();

    const overlayRef = useRef(null);
    const [openOverlay, setOpenOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState();

    const activeShipping = useSelector(state => state.productpage.shipping);
    const activeSingleTemplate = useSelector(state => state.cart.template);
    const activeCartSubproducts = useSelector(state => state.cart.subproducts);

    const [activeTemplateId, setActiveTemplateId] = useState(subnum !== false ? activeCartSubproducts[subnum].activeTemplate : activeSingleTemplate);

    const linklabel = templateCount > 1 ? 'Design auswählen' : 'Vorschau ansehen';
    const boxheadline = templateCount > 1 ? 'Design auswählen' : 'Vorschau';

    const chooseTemplate = (templateId, e) => {
        e.target.parentElement.parentElement.childNodes.forEach((c) => {
            c.classList.remove('active');
        })
        e.target.parentElement.classList.add('active');

        if (subnum) {
            let cartData = activeCartSubproducts;
            cartData[subnum].activeTemplate = templateId;
            let newCartData = { ...activeCartSubproducts, ...cartData };
            dispatch(CartStore.setCartSubproducts(newCartData));
        }
        else {
            dispatch(CartStore.setCartTemplate(templateId));
        }
        setActiveTemplateId(templateId);
    }


    const setTemplateLoaded = (e) => {
        e.target.classList.remove('hidden');
        e.target.previousSibling.remove();
    }


    const loadDesignOverlay = () => {
        setOverlayContent();
        setOpenOverlay(true);
        const API_URL = global.config.express.url + '/producttemplates';
        const API_DATA = {
            'productId': productId
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            let selection = [];
            response.templates.forEach(t => {
                selection.push(
                    <div className={"imgholder" + (t.id === activeTemplateId ? ' active' : '')} key={t.id}>
                        <div className="spinner"></div>
                        <img className="hidden" src={t.imgSrc} alt="" onClick={(e) => chooseTemplate(t.id, e)} onLoad={(e) => setTemplateLoaded(e)} />
                    </div>)
            });

            let overlaycontent =
                <div className="gw_slide_overlay t-center">
                    <div className="infobox templates">
                        <button type="button" className="gw_close" onClick={() => setOpenOverlay(false)}>X</button>
                        <div className="inner">
                            <h3>{boxheadline}</h3>
                            <div className="overlayholder small" ref={overlayRef}>
                                <div className="overlaycontholder">
                                    <div className="templateselection">
                                        {selection}
                                    </div>
                                    <p><button type="button" onClick={() => setOpenOverlay(false)}>Fenster schließen</button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

            setOverlayContent(overlaycontent);
        })
    }

    let cont = '';
    if (templateCount >= 1 && activeShipping.type === 'email') {
        cont = <button className={"designbutton" + (notype ? ' top' : '')} type="button" onClick={() => loadDesignOverlay()}>{linklabel}</button>;
    }


    let overlaycontent = openOverlay ? overlayContent : '';


    return (
        <>
            {overlaycontent ? overlaycontent : ''}
            {cont}
        </>
    );

}

export default ProductTemplateButton;
