import { useSelector } from 'react-redux'

const ProductContent = () => {

    const product = useSelector(state => state.productpage.data);

    if (typeof(product) !== 'undefined' && product.length !== 0){

        let contentimage;
        product.products['product-bundle-medias'].forEach((image) => {
            if (image['media-kind'] === 'contentelement'){
                contentimage = <img src={global.config.apidata.url + '/' + image['relative-path']} alt={image['alt']} />
            }
        });

        if (product.products['description-details']){
            let text = <div className="text" dangerouslySetInnerHTML={{ __html: product.products['description-details'] }} />
            return (
                <>
                <div className="container nopadding details">
                    <div className="row flex">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            {text}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 detailsimage mtop">
                            {contentimage}
                        </div>
                    </div>
                </div>
                </>
            );
        }
    }
}

export default ProductContent;
