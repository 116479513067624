import { useRef, useState } from "react";
import HelperService from "../../../services/helper.service";
import HideOpenedSelect from "../../helper/HideOpenedSelect";
import ProductOptionsShipping from './ProductOptionsShipping';

import { useSelector } from 'react-redux';


const ProductFormOptionsShipping = ({setCouponActivePrice}) => {

    const activeProductData = useSelector(state => state.productpage.productdata);
    const activeShipping = useSelector(state => state.productpage.shipping);
    const [shippingSelect, setShippingSelect] = useState('hidden');

    const wrapperRef = useRef(null);
    HideOpenedSelect(wrapperRef, shippingSelect, setShippingSelect);

    return (
        <>      
        <div className="input-holder" ref={wrapperRef}>
            <label>Versand:</label>
            <input onClick={() => setShippingSelect('visible')} className="selections shipping" type="text" value={activeShipping.name + (activeShipping.price !== 0 ? ' ' + HelperService.formatPrice(activeShipping.price) : '')} autoComplete="off" readOnly disabled={activeProductData.contingent === 0 || (activeProductData.contingent !== null && activeProductData['minimum-quantity']) > activeProductData.contingent ? 'disabled' : ''} />
            <div className={shippingSelect + " select_options"}>
                <div className="hl hidden visible-xs"><span className="label">Versand</span><span className="close">x</span></div>
                <ProductOptionsShipping type="select" mobile="no"  setCouponActivePrice={setCouponActivePrice} setselect={setShippingSelect} />
            </div>
        </div>
        </>
    )

}

export default ProductFormOptionsShipping;