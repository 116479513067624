import CouponCode from './elements/CouponCode';
import OrderTable from './elements/OrderTable';
import PaymentButtons from './elements/PaymentButtons';
import Summary from './elements/Summary';

const ShoppingCartContent = ({cart, setActiveCart}) => {       
    
    return (
        <>                     
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <OrderTable products={cart['order-product-bundle-groups']} cart={cart} setActiveCart={setActiveCart} />          
            <div className="row mflex">  
                <div className="col-lg-4 col-md-6 col-sm-7 col-xs-12 mf2">                
                    <CouponCode cart={cart} setActiveCart={setActiveCart} />
                </div>
                <div className="col-lg-8 col-md-6 col-sm-5 col-xs-12 t-right mf1">
                    <Summary cart={cart} />        
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 t-right mf3'>
                    <div className='cartcheckoutbuttons'>
                        <PaymentButtons cart={cart} />
                    </div>
                </div>
            </div>                   
        </div>                        
        </>
    );    
}

export default ShoppingCartContent;
