import { useNavigate } from 'react-router-dom'
import ApiService from "../../../services/api.service";

const ProductBotFields = () => {

    const navigate = useNavigate();

    function changeField(e){            
        const API_URL = global.config.express.url + '/botfieldchange';
        const API_DATA = {
            'url': window.location.href,
            'agent': "Felder wurden direkt geändert.\n\n" + navigator.userAgent
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {  
            if (response){
                navigate('?path=bestellung-fehlgeschlagen', {replace: true});
            }      
        });         
    }    

    return (
        <>
        <input type="hidden" name="name" value="" onChange={(e) => changeField(e)} />
        <input type="hidden" name="fail" value="A17B13" required onChange={(e) => changeField(e)} />
        </>
    );

}

export default ProductBotFields;
